const routes = [
  {
    equals: '/',
    app: 'dashboard',
    requiresFedexAccount: true,
  },
  {
    startsWith: '/login',
    name: 'loginRoutingApp',
    app: 'login',
    isProtected: false,
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: ['/signup', '/update-password'],
    app: 'authV2',
    isProtected: false,
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: ['/forgot-password', '/change-password','/password-updated'],
    app: 'changePassword',
    isProtected: false,
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/setup',
    name: 'setup',
    app: 'changePassword',
    isProtected: false,
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  // {
  //   startsWith: '/enquiries',
  //   app: 'enquiriesV2',
  //   exclude: { equals: '/enquiriesV2' },
  //   isProtected: true,
  //   // requiresFedexAccount: true,
  // },
  {
    startsWith: '/enquiries',
    app: 'enquiries',
    isProtected: true,
    // requiresFedexAccount: true,
  },
  {
    startsWith: '/feedback',
    app: 'feedback',
    isProtected: true,
  },
  {
    startsWith: '/terms',
    app: 'termsAndConditions',
    isProtected: true,
  },
  {
    startsWith: '/shipment-planner',
    app: 'shipmentPlanner',
    startsWith: '/linkFedexAccount',
    name: 'linkFedexAccountRoutingApp',
    app: 'linkFedexAccount',
    isProtected: true,
  },
  // {
  //   startsWith: '/enquiries',
  //   app: 'enquiries',
  //   requiresFedexAccount: true,
  // },
  {
    startsWith: '/shipment-planner',
    app: 'shipmentPlanner',
    requiresFedexAccount: true,
  },
  {
    startsWith: '/bookings',
    app: 'bookings',
    isProtected: true,
  },
  {
    startsWith: '/shipments',
    app: 'shipments',
    isProtected: true,
    // requiresFedexAccount: true,
  },
  {
    startsWith: '/documents',
    app: 'documents',
    isProtected: true,
  },
  {
    startsWith: '/tracking',
    app: 'tracking',
    exclude: { startsWith: ['/tracking/shared', '/tracking/v2'] },
    requiresFedexAccount: true,
  },
  {
    startsWith: '/tracking/v2',
    app: 'trackingV2',
  },
  {
    startsWith: '/shared-tracking/',
    name: 'sharedTracking',
    app: 'tracking',
    isProtected: false,
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/pickups',
    app: 'pickups',
    requiresFedexAccount: true,
  },
  {
    startsWith: '/contacts',
    app: 'contacts',
    requiresFedexAccount: true,
  },
  {
    startsWith: '/rates',
    app: 'ratesV3',
  },
  {
    startsWith: '/admin',
    app: 'admin',
  },
  {
    startsWith: '/shippers',
    app: 'shippers',
  },
  {
    startsWith: '/internal-user',
    app: 'staffs',
  },
  {
    startsWith: '/providers',
    app: 'providers',
  },
  {
    startsWith: '/account',
    app: 'customers',
  },
  {
    startsWith: '/help',
    app: 'help',
  },
  {
    startsWith: '/insights',
    app: 'reports',
  },
  {
    startsWith: '/watch-tower',
    app: 'watchTower',
  },
  {
    startsWith: '/gsr',
    app: 'globalShipmentRadar',
  },
];

export default routes;
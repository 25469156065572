function getQueryArray(key, value) {
  if (!key || typeof(key)!=='string')
    return '';
  if (Array.isArray(value))
    return value.map((v, i) => getQueryArray(`${key}[${i}]`, v)).join('&');
  if (value instanceof Date)
    return `${key}=${value}`;
  if (typeof(value)==='object')
    return Object.entries(value).filter(([_, v]) => !!v).map(([k,v]) => getQueryArray(`${key}[${k}]`, v)).filter(v => !!v).join('&');
  return `${key}=${value}`;
}

export default function objectToQueryString(queryObject) {
  if (!queryObject || typeof(queryObject)!=='object' || Array.isArray(queryObject))
    return '';
  return Object.entries(queryObject).map(([key, value]) => getQueryArray(key,value)).reduce((final, curr) => final.concat(curr), []).join('&');
}
import { combineReducers } from 'redux';
import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';

const { reducer: $watchTower, actions: watchTower, epics: watchTowerEpics } = listDux({
  name: 'watchTower',
  url: '/api/tracking/v2/tracking/containers-at-pod',
});

const { reducer: $gsr, actions: gsr, epics: gsrEpics } = listDux({
  name: 'gsr',
  url: '/api/tracking/v2/tracking/current-shipments',
});

const { reducer: $listEventLog, actions: listEventLog, epics: listEventLogEpics } = listDux({
  name: 'listEventLog',
  url: '/api/tracking/v2/push-events'
});

const { reducer: $eventLogDetail, actions: eventLogDetail, epics: eventLogDetailEpics } = detailsDux({
  name: 'eventLogDetail',
  url: '/api/tracking/v2/push-events'
});

const { reducer: $trackingDetail, actions: trackingDetail, epics: trackingDetailEpics } = detailsDux({
  name: 'trackingDetail',
  url: '/api/tracking/v2/tracking/track-by-reference',
  idField: 'referenceNumber',
  getUrl: (url, { 0: { payload: { referenceNumber } = {} } = {} }) => `${url}?referenceNumber=${referenceNumber}`,
});

export const actions = { listEventLog, eventLogDetail, trackingDetail, watchTower, gsr};
export const epics = [...listEventLogEpics, ...eventLogDetailEpics, ...trackingDetailEpics, ...watchTowerEpics, ...gsrEpics];
export default combineReducers({ $listEventLog, $eventLogDetail, $trackingDetail, $watchTower, $gsr});
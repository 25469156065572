import { combineReducers } from 'redux';
import listDux from './lib/listDux';


const processResponse = ([{ response = [], status, }, [{ payload: { query, isFetching } }]] = []) => {
  if (status == 200)
    return { items: response.data, count: response.data?.length, query, isFetching };
  return { error: 'Server Error', errorMessage: 'Server Error', query, isFetching };
};

const { reducer: $monthlyShipmentsList, actions: monthlyShipmentsList, epics: monthlyShipmentsListEpics } = listDux({
  name: 'monthlyShipmentsList',
  url: '/api/shipments/v2/monthly-shipments/',
  processResponse,
});

const { reducer: $shipmentsByOriginList, actions: shipmentsByOriginList, epics: shipmentsByOriginListEpics } = listDux({
  name: 'shipmentsByOriginQuery',
  url: '/api/shipments/v2/shipments-by-origin/',
  processResponse,
});

const { reducer: $shipmentsByDestinationList, actions: shipmentsByDestinationList, epics: shipmentsByDestinationListEpics } = listDux({
  name: 'shipmentsByDestinationQuery',
  url: '/api/shipments/v2/shipments-by-destination/',
  processResponse,
});

export const actions = { monthlyShipmentsList, shipmentsByOriginList, shipmentsByDestinationList };
export const epics = [...monthlyShipmentsListEpics, ...shipmentsByDestinationListEpics, ...shipmentsByOriginListEpics];

export default combineReducers({ $monthlyShipmentsList, $shipmentsByOriginList, $shipmentsByDestinationList });
